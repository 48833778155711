import { render, staticRenderFns } from "./SetNewPassword.vue?vue&type=template&id=2e9ed46a&scoped=true&"
import script from "./SetNewPassword.vue?vue&type=script&lang=js&"
export * from "./SetNewPassword.vue?vue&type=script&lang=js&"
import style0 from "./SetNewPassword.vue?vue&type=style&index=0&id=2e9ed46a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e9ed46a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VForm,VProgressCircular,VRow,VSheet,VSnackbar,VTextField})
